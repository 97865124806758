import { ReactNode } from 'react';
import styles from './styles/Page.module.scss';

export default function Page({ children, subNav }: { children: ReactNode; subNav?: ReactNode }) {
  return (
    <>
      {subNav && <header className={styles.FixedHeader}>{subNav}</header>}
      <main className={styles.Main}>{children}</main>
    </>
  );
}
