import AdPlaceholder from './AdPlaceholder';
import cn from 'classnames';
import styles from './styles/MinuteMediaAd.module.scss';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import { MINUTE_MEDIA_ADS_ENABLED, USE_MINUTE_MEDIA_ADS } from 'constants.js.erb';

const minuteMediaPlacements = {
  TOP: 'div-gpt-ad-1552218170642-0',
  BOTTOM: 'div-sticky-bottom',
  SIDEBAR: 'div-sideBar1',
  SIDEBAR_2: 'div-sideBar2',
  IN_FEED: 'below-top-section',
  IN_FEED_2: 'below-second-section'
};

export type MinuteMediaPlacementType = keyof typeof minuteMediaPlacements;

export default function MinuteMediaAd({ placement }: { placement: MinuteMediaPlacementType }) {
  const [{ disableAds }] = useApplicationContainer();

  if (disableAds || !USE_MINUTE_MEDIA_ADS) return null;

  return (
    <AdPlaceholder
      className={cn(styles.Ad, styles[placement], { [styles.Placeholder]: !MINUTE_MEDIA_ADS_ENABLED })}
      id={minuteMediaPlacements[placement]}
      placeholder={!MINUTE_MEDIA_ADS_ENABLED}
    />
  );
}
