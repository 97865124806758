import { ContentContainer, ContentContainerProps } from 'containers/ContentContainer';
import ContentIndexLayout, { ContentIndexLayoutProps } from 'containers/ContentContainer/components/ContentIndexLayout';
import { entryPoint } from 'react_rails/entryPoint';
import Page from '@/components/Page';

export default entryPoint(function Index({ showHero, showRight, ...props }: ContentContainerProps & ContentIndexLayoutProps) {
  return (
    <Page hasAdPlacements>
      <ContentContainer {...props}>
        <ContentIndexLayout showHero={showHero} showRight={showRight} />
      </ContentContainer>
    </Page>
  );
});
