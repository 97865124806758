import Clickable from '@/components/Clickable';
import styles from './styles/Footer.module.scss';
import { useEffect, useState } from 'react';
import cn from 'classnames';

export default function Footer() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      if (scrollTop / window.innerHeight > 4) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const year = new Date().getFullYear();

  return (
    <footer className={cn(styles.Footer, { [styles.Sticky]: isSticky })}>
      <div className={styles.FooterNav}>
        <ul className={styles.FooterNavMain}>
          <li>
            <Clickable href="/about">ABOUT</Clickable>
          </li>
          <li>
            <Clickable href="https://sites.google.com/scorebooklive.com/sblive-academy/home?authuser=0">SUPPORT</Clickable>
          </li>
        </ul>
        <ul className={styles.FooterSubnav}>
          <li>
            <Clickable href="/privacy">PRIVACY POLICY</Clickable>
          </li>
          <li>
            <Clickable href="/terms">TERMS & CONDITIONS</Clickable>
          </li>
          <li>
            <Clickable href="/privacy-choices">YOUR PRIVACY CHOICES</Clickable>
          </li>
        </ul>
      </div>
      <div className={styles.FooterIcons}>
        <Clickable
          className="footer__icon twitter--grey"
          href="https://twitter.com/sblivesports"
          target="_blank"
          rel="noopener"
          aria-label="SBLive Sports on Twitter"
        />
        <Clickable
          className="footer__icon facebook--grey"
          href="https://www.facebook.com/sblivesports/"
          target="_blank"
          rel="noopener"
          aria-label="SBLive Sports on Facebook"
        />
        <Clickable
          className="footer__icon instagram--grey"
          href="https://www.instagram.com/sblivesports"
          target="_blank"
          rel="noopener"
          aria-label="SBLive Sports on Instagram"
        />
        <Clickable
          className="footer__icon youtube--grey"
          href="https://www.youtube.com/channel/UCQh8R2Yc_niiIinD7Qz0NSg"
          target="_blank"
          rel="noopener"
          aria-label="SBLive Sports on YouTube"
        />
        <Clickable
          className="footer__icon linkedin--grey"
          href="https://www.linkedin.com/company/scorebook-live/"
          target="_blank"
          rel="noopener"
          aria-label="SBLive Sports on LinkedIn"
        />
      </div>
      <div className={styles.FooterCopyright}>Copyright {year} Scorebook Live, Inc.</div>
    </footer>
  );
}
