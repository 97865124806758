import styles from './styles/AdPlaceholder.module.scss';
import { HTMLAttributes } from 'react';
import cn from 'classnames';

export default function AdPlaceholder({
  className,
  placeholder,
  ...props
}: Omit<HTMLAttributes<HTMLDivElement>, 'placeholder'> & { placeholder?: boolean }) {
  return <div className={cn({ [styles.Placeholder]: placeholder }, className)} {...props} />;
}
