import styles from './styles/StnVideoAd.module.scss';
import AdPlaceholder from './AdPlaceholder';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import { STN_ENABLED } from 'constants.js.erb';
import cn from 'classnames';

export default function StnVideoAd({ placement, className }: { placement: 'TOP' | 'INLINE'; className?: string }) {
  const [{ disableVideoAd }] = useApplicationContainer();

  if (disableVideoAd) return null;

  return (
    <div className={cn(styles.Container, placement === 'TOP' && styles[placement], className)}>
      <div className={styles.Spacer}>
        <AdPlaceholder className={styles.Ad} id="stn-player" placeholder={!STN_ENABLED} />
      </div>
    </div>
  );
}
